import './zone-flags';
import 'zone.js';

import '@angular/localize/init';

import toBlob from 'canvas-to-blob';

import { environment } from './environments/environment';
import { logger } from './logger';

// prevent promise overriding
// @see https://issues.goodvisionlive.com/youtrack/issue/GVVI-2796
const origDefineProperty = Object.defineProperty;
Object.defineProperty = (
  o: any,
  p: PropertyKey,
  attributes: PropertyDescriptor & ThisType<any>,
): any => {
  if (o === global && p === 'Promise') {
    if (attributes.value) {
      o[p] = attributes.value;
    } else if (attributes.get) {
      o[p] = attributes.get();
    } else {
      logger.error('Failed to override Promise');
    }
    return o;
  }
  return origDefineProperty.call(Object, o, p, attributes);
};

// polyfill for canvas toBlob function (edge browser)
toBlob.init();

// see issue https://github.com/AngularClass/angular2-webpack-starter/issues/709
// import 'core-js/es6/promise';

// @see https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
if (!Element.prototype.matches) {
  Element.prototype.matches =
    (Element.prototype as any).msMatchesSelector ||
    // eslint-disable-next-line deprecation/deprecation
    Element.prototype.webkitMatchesSelector;
}

// Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
// throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
// to avoid the entire page breaking, without having to do a check at each usage of Storage.
// @see https://stackoverflow.com/questions/21159301/quotaexceedederror-dom-exception-22-an-attempt-was-made-to-add-something-to-st/27081419#27081419
// tslint:disable-next-line: no-restricted-globals
if (typeof localStorage === 'object') {
  try {
    localStorage.setItem('localStorage', '1'); // tslint:disable-line: no-restricted-globals
    localStorage.removeItem('localStorage'); // tslint:disable-line: no-restricted-globals
  } catch (e) {
    Storage.prototype._setItem = Storage.prototype.setItem;
    Storage.prototype.setItem = () => undefined;

    alert(
      `Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.`,
    );
  }
}

if (environment.production) {
  // Production
} else {
  Error.stackTraceLimit = Infinity;
}

export const environment = {
  api: {
    staticBaseUrl: '/api/v1',
    lambdaStaticBaseUrl: '/lapi',
    useStaticBaseUrl: false,
    xsrfToken: undefined,
  },
  electron: false,
  production: true,
  routing: {
    useHashLocationStrategy: false,
  },
  serviceWorker: true,
  test: false,
};

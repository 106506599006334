import { configure } from 'safe-stable-stringify';
import type { Logger, MessageContext, MessageSerializer } from 'roarr';
import { ROARR, Roarr } from 'roarr';
import { deserializeError, serializeError, isErrorLike } from 'serialize-error';

export declare type ContextType = Error | Date | unknown;

function replacer(key: string, value: unknown) {
  return isErrorLike(value) ? serializeError(value) : value;
}

const safeStringify = configure({
  deterministic: false,
  maximumBreadth: 20,
  maximumDepth: 10,
  strict: false,
});

function serializeToJSON(data: unknown): string {
  return safeStringify(data, replacer)!;
}

function createReviver() {
  const errors: Error[] = [];
  function reviver(key: string, value: unknown) {
    if (isErrorLike(value)) {
      const error = deserializeError(value);
      errors.push(error);
      return error;
    }
    return value;
  }
  return { errors, reviver };
}

export function deserializeFromJSON(data: string): {
  value: unknown;
  errors: Error[];
} {
  const { errors, reviver } = createReviver();
  return { errors, value: JSON.parse(data, reviver) };
}

const serializeMessage: MessageSerializer = serializeToJSON;

ROARR.serializeMessage = serializeMessage;

export const BaseLogger = Roarr as Logger<
  MessageContext | Record<string | symbol | number, ContextType>
>;
export type { Logger };

export const createPackageLogger = (
  parent: Logger<
    MessageContext | Record<string | symbol | number, ContextType>
  >,
  pkg: string,
) =>
  parent.child({
    package: pkg,
  });

export const createComponentLogger = (
  parent: Logger<
    MessageContext | Record<string | symbol | number, ContextType>
  >,
  component: string,
) =>
  parent.child({
    namespace: component,
  });
